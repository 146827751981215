import './Header.css';

function Header() {
  return (
    <div className="header">
        <div className="containerTitle">
            <h1 className="title">Fernando Junior</h1>
        </div>
        <div className="containerLinks">  
            
            <a href="https://blog.fernandojunior.net">
                <div className="btnBlog" >
                    Blog
                </div>
            </a>

            <a href="https://www.linkedin.com/in/fernando-junior-bnu/">
                <div className="btnLinkedIn">
                    LinkedIn
                </div>
            </a>
            
            
        </div>
    </div>
  );
}

export default Header;
